module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jaen App","short_name":"Jaen","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"src/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"94900edb0b0fa72e52297256a435fde6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-M58K75M9PG"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"sampleRate":1,"enableTracing":true,"debug":true,"tracesSampleRate":1,"replaysSessionSampleRate":1,"replaysOnErrorSampleRate":1},
    },{
      plugin: require('../node_modules/gatsby-plugin-jaen/gatsby-browser.tsx'),
      options: {"plugins":[],"remote":{"repository":"netsnek/fhkit.at"},"zitadel":{"organizationId":"260976344454660099","clientId":"252746210698395651@services","authority":"https://accounts.cronit.io","redirectUri":"https://fhkit.at"},"googleAnalytics":{"trackingIds":["G-M58K75M9PG"]}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
